<template>
  <section>
    <h2 class="uppercase pl-4 md:pl-28 pt-4 text-gray-600">
      Sie sind hier / <a style="color: #f27405" href="/home">Beroma Gmbh</a> /
      Markisen / Halbkassettenmarkisen / Family Compact <br />
    </h2>
    <article class="container mx-auto pl-2 pt-2 lg:p-32">
      <h2 class="text-4xl md:text-6xl pb-10" style="color: #f27405">
        Lewens - Family Classic Compact
      </h2>
      <div class="grid grid-cols-2">
        <div class="col-span-full md:col-span-1">
          <productslide v-bind:slides="slides"></productslide>
        </div>
        <div class="col-span-full md:col-span-1">
          <h2
            class="text-2xl pl-5 py-5 md:pl-10 md:pb-3"
            style="color: #033859"
          >
            Zuverlässig geschützt auf Balkon und Terrasse
          </h2>
          <p class="px-5 w-full md:pl-10 md:pb-3">
            Klassisches Design, überlegene Qualität, einfache Bedienbarkeit und
            solide Wertbeständigkeit: Die Family Compact vereint die
            Schutzfunktion einer Kassettenmarkise mit dem Montagekomfort einer
            Tragrohr-Markise.
          </p>
        </div>
      </div>
      <div class="p-4">
        <dh3iv class="text-4xl pt-10 pb-3" style="color: #033859"
          >Eckdaten</dh3iv
        >
        <tabs :mode="mode">
          <tab title="Typen">
            <div class="text-lg">
              <span class="font-bold"
                >Erhältlich in verschiedene Produktvarianten
              </span>
              <br />
              <div class="flex flex-col sm:flex-row">
                <figure class="px-2 w-64">
                  <img
                    src="../assets/img/lewens/familycompact/lew-fam-compact-hk-detail-kappe-orange-xl.webp"
                    alt="family compact halbkassette"
                  />Family Compact Typ Halbkassette
                </figure>
                <figure class="px-2 w-64">
                  <img
                    src="../assets/img/lewens/familycompact/lew-fam-compact-k-detail-kappe-orange-xl.webp"
                    alt="family compact vollkassette"
                  />Family Compact Typ Kassette
                </figure>
              </div>
            </div>
          </tab>
          <tab title="Maßen">
            <p class="text-lg">
              <span class="font-bold">Breite:</span> <br />
              max. 650 cm - 1-teilig (Einzelfeld)<br />
              max. 1.950 cm - 2-/3-teilig (mit Schlitzabdeckung) <br />
              <span class="font-bold">Ausfall/Tiefe:</span> <br />
              max. 350 cm <br />
              <span class="font-bold">Antrieb:</span> <br />
              Motorantrieb <br />
              Kurbelantrieb (Option)<br />
              <span class="font-bold">Volant:</span> <br />
              Volant möglich (Option) <br />
              <span class="font-bold">Markisentücher:</span> <br />
              Marken-Acryl <br />
              Marken-Polyester (Option)
            </p>
          </tab>
          <tab title="Zertifizierung">
            <div class="text-lg">
              <span class="font-bold">Zertifizierung:</span> <br />
              <div class="flex flex-col">
                <div class="flex flex-row">
                  <div>
                    <img
                      src="../assets/madeingermany.webp"
                      alt="made in germany"
                    />
                  </div>
                  <div class="w-full pl-1">
                    Hergestellt und montiert in Deutschland
                  </div>
                </div>
                <div class="flex flex-row">
                  <div><img src="../assets/ce-norm.webp" alt="ce-norm" /></div>
                  <div class="w-full pl-1">
                    Qualität und Technik nach CE-Norm
                  </div>
                </div>
                <div class="flex flex-row">
                  <div>
                    <img src="../assets/tuev.webp" alt="tuv geprueft" />
                  </div>
                  <div class="w-full pl-1">TÜV-geprüfte Sicherheit</div>
                </div>
              </div>
            </div>
          </tab>
        </tabs>
      </div>
    </article>
  </section>
</template>
<style>
button:focus {
  color: #f27405;
}
button::-moz-focus-inner {
  border: 0;
}
</style>
<script>
import Tab from "../components/Tab";
import Tabs from "../components/Tabs";
import productslide from "../components/product-slide";

export default {
  components: {
    Tab,
    Tabs,
    productslide,
  },
  data() {
    return {
      slides: [
        {
          image: require("@/assets/img/lewens/familycompact/lew-fam-compact-ganz-haus1-2000.webp"),
          alt: "halbkassettenmarkise lewens family compact",
        },
        {
          image: require("@/assets/img/lewens/familycompact/lew-fam-compact-ganz-haus2-2000.webp"),
          alt: "halbkassettenmarkise family compact ausgefahren",
        },
        {
          image: require("@/assets/img/lewens/familycompact/modelle-web-rd-4.webp"),
          alt: "halbkassettenmarkise family compact modell ansicht",
        },
        {
          image: require("@/assets/img/lewens/familycompact/lew-fam-compact-hk-detail-kappe-orange-xl.webp"),
          alt: "halbkassette typ",
        },
        {
          image: require("@/assets/img/lewens/familycompact/lew-fam-compact-k-detail-kappe-orange-xl.webp"),
          alt: "vollkassette typ",
        },
        {
          image: require("@/assets/img/lewens/familycompact/lew-fam-compact-detail-armhalter-x2.webp"),
          alt: "family compact armhalter",
        },
        {
          image: require("@/assets/img/lewens/familycompact/lew-family-detail-armmittelgelenk2-xl-8.webp"),
          alt: "family compact armmittelgelenk",
        },
      ],
    };
  },
};
</script>
